<template>
  <div class="title" :class="{ noBorder: noBorder }">
    <div class="content">
      {{ showTitle }}
      <div v-if="des" class="uiText" :style="{ color: desColor }">
        {{ des }}
      </div>
    </div>
    <div v-if="jumpObj.text" class="jump" @click="jump">{{ jumpObj.text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    noBorder: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    des: {
      type: String,
      default: '',
    },
    desColor: {
      type: String,
      default: '#666666',
    },
    jumpObj: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    showTitle() {
      let result = this.title
      console.log('result', result)
      if (result.includes('.')) {
        console.log('xx')
        result = this.$t(result)
      }
      return result
    },
  },
  methods: {
    jump() {
      const path = this.jumpObj.path
      const event = this.jumpObj.event
      if (path) {
        this.$router.push({
          path: this.jumpObj.path,
          query: {
            id: this.jumpObj.id,
          },
        })
      } else if (event) {
        this.$emit(event)
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 68px;
  padding: 0 30px;
  background: #fff;
  border-bottom: 1px solid #eeeeee;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 17px;
    background: @uni-text-color-select;
    border-radius: 2px;
  }
  .content {
    display: flex;
    align-items: center;
  }
  .uiText {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .jump {
    width: 120px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid @uni-text-color-select;
    font-size: 14px;
    font-weight: 400;
    color: @uni-text-color-select;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }
}
.noBorder {
  border: none;
}
</style>
