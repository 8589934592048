<template>
  <div class="container">
    <Title :title="$t('7e0fbbd')" :des="$t('08745e6')" />
    <ReleaseDemand />
  </div>
</template>

<script>
import Title from "@/components/Title"
import ReleaseDemand from "@/components/ReleaseDemand"

import api from "@/api/request"

export default {
  name: "ReleaseRequest",
  components: {
    Title,
    ReleaseDemand,
  },
  props: {},
  data() {
    return {
      values: {}
    }
  },

  mounted() {
    const id = this.$route.query.id
    if (id) {
      api.getDetail(id).then((res) => {
        this.values = res
      })
    }
  },
  methods: {},
}
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.container {
  width: 100%;
  background: #fff;
}
</style>
