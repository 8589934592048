<template>
  <el-form
    ref="releaseForm"
    :model="releaseForm"
    :rules="rules"
    class="releaseForm"
    label-width="auto"
  >
    <el-form-item
      v-if="!(isLaw || isTranslate)"
      :label="autoPrefix('demandType') + ''"
      prop="demandType"
    >
      <el-radio-group
        v-model="releaseForm.demandType"
        :disabled="editId >= 0"
        @change="changeRadio"
      >
        <el-radio
          v-for="(item, index) of demandTypeOptions"
          :key="index"
          :label="item.key"
        >
          {{ $t(item.name) }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item :label="autoPrefix('businessType')" prop="businessType">
      <el-select
        v-model="releaseForm.businessType"
        clearable
        multiple
        :placeholder="autoPrefix('businessType', 'pla')"
      >
        <el-option
          v-for="item in fieldOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="autoPrefix('title')" prop="title">
      <el-input
        v-model="releaseForm.title"
        maxlength="150"
        show-word-limit
        :placeholder="autoPrefix('title', 'pla')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('content')" prop="content">
      <el-input
        v-model="releaseForm.content"
        class="contentInput"
        type="textarea"
        maxlength="1000"
        :rows="7"
        :placeholder="autoPrefix('content', 'pla')"
        show-word-limit
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('timeLimit')" prop="timeLimit">
      <el-date-picker
        v-model="releaseForm.timeLimit"
        :picker-options="pickerOptions"
        type="daterange"
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
    </el-form-item>
    <div class="countryContainer">
      <el-form-item
        class="country"
        :label="autoPrefix('country')"
        prop="country"
      >
        <el-select
          v-model="releaseForm.country"
          :placeholder="autoPrefix('country', 'pla')"
        >
          <el-option
            v-for="item in dictionaryList.content"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="releaseForm.country === 1"
        class="province"
        prop="province"
        label-width="0"
      >
        <el-select
          v-model="releaseForm.province"
          :placeholder="autoPrefix('province', 'pla')"
        >
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </div>

    <el-form-item :label="autoPrefix('urgentStatus')" prop="urgentStatus">
      <el-select v-model="releaseForm.urgentStatus" placeholder="请选择状态">
        <el-option
          v-for="item in urgentStatusOptions"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="autoPrefix('contacts')" prop="contacts">
      <el-input
        v-model="releaseForm.contacts"
        :placeholder="autoPrefix('contacts', 'pla')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('telephone')" prop="telephone">
      <el-input
        v-model="releaseForm.telephone"
        :placeholder="autoPrefix('telephone', 'pla')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('email')" prop="email">
      <el-input
        v-model="releaseForm.email"
        :placeholder="autoPrefix('email', 'pla')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('annex')">
      <el-upload
        class="uploadannex"
        :headers="headers"
        :action="actionUrl"
        :limit="1"
        :accept="accept.join()"
        :file-list="fileList"
        :on-success="onSuccess"
        :on-remove="handleRemove"
        :before-upload="beforeAvatarUpload"
      >
        <el-button class="addButton">+ {{ $t('60d62d8') }}</el-button>
        <div slot="tip" class="el-upload__tip">
          {{ $t('1765d28') }}
        </div>
      </el-upload>
    </el-form-item>

    <el-form-item class="buttonCntainer">
      <el-button class="cancel" @click="cancelSubmitForm('releaseForm')">
        {{ $t('6b0feee') }}
      </el-button>
      <el-button class="release" @click="submitForm('releaseForm')">
        {{ submitFormText }}
      </el-button>
    </el-form-item>

    <el-form-item class="warning">
      {{ $t('37bbf16') }}！
    </el-form-item>
  </el-form>
</template>

<script>
import API from '@/api'
import { mapGetters, mapActions } from 'vuex'
// const i18nPrefix = {
//   label: 'account.register.releaseForm.lable.',
//   pla: 'account.register.releaseForm.pla.',
// }
import { getToken } from '@/utils/auth'
const actionUrl = '/api/frLegalPlatform/common/qiniu/upload'
import validator from '@/utils/validation'
import { DEMAND_TYPE } from '@/utils/constants'
export default {
  name: 'ReleaseDemand',
  props: {
    editId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      accept: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      headers: {
        accessToken: getToken(),
      },
      actionUrl: actionUrl,
      pickerMinDate: '',
      pickerOptions: {
        onPick: (obj) => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          if (obj.maxTime) {
            this.pickerMinDate = ''
          }
        },
        disabledDate: (time) => {
          time = time.getTime()
          const curentTime = new Date().getTime()
          const val = curentTime - 24 * 3600000 > time
          if (this.pickerMinDate) {
            const day1 = 30 * 24 * 3600 * 1000
            const maxTime = this.pickerMinDate + day1
            const minTime = this.pickerMinDate - day1
            const result = time > maxTime || time < minTime
            return result || val
          } else {
            return val
          }
        },
      },
      fileList: [],
      demandTypeOptions: DEMAND_TYPE,
      urgentStatusOptions: [
        {
          value: 'EMERGENCY',
          label: '9629065',
        },
        {
          value: 'NORMAL',
          label: 'cc153fe',
        },
      ],
      label: {
        demandType: 'bddb155',
        businessType: 'f2752de',
        title: '23f0457',
        content: '484c370',
        timeLimit: 'c39dc7d',
        country: '70822d6',
        urgentStatus: '8e6a9a8',
        contacts: '8630098',
        telephone: 'db8d921',
        email: '5bfb52e',
        annex: '6abb025',
      },
      pla: {
        demandType: 'bddb155',
        businessType: '6fd5fc3',
        title: '23f0457',
        content: '180fb57',
        timeLimit: 'c39dc7d',
        areaInvolved: '70822d6',
        urgentStatus: '8c7aeca',
        contacts: '97ea28f',
        telephone: '18b2b78',
        email: '133bec8',
        annex: '6abb025',
      },
      releaseForm: {
        demandType: 'LAW',
        businessType: '',
        title: '',
        content: '',
        timeLimit: [],
        country: '',
        province: '',
        urgentStatus: '',
        contacts: '',
        telephone: '',
        email: '',
        annex: '',
        fileName: '',
      },

      businessTypeList: [],
    }
  },
  computed: {
    ...mapGetters([
      'dictionaryList',
      'detailsData',
      'provinceList',
      'userInfo',
      'dictionaries',
    ]),
    rules() {
      return {
        demandType: [
          { required: true, message: this.$t('6fd5fc3') },

        ],
        businessType: [
          { required: true, message: this.$t('6fd5fc3') },
        ],
        title: [{ required: true, message: this.$t('8383f9d') }],
        content: [
          { required: true, message: this.$t('d76d678') },
        ],
        timeLimit: [
          { required: true, message: this.$t('5775c2e') },
          // { validator: lenIs6 },
        ],
        country: [
          { required: true, message: this.$t('b500880') },
          // { validator: lenIs6 },
        ],
        province: [
          { required: true, message: this.$t('328d61d') },
          // { validator: lenIs6 },
        ],
        urgentStatus: [
          { required: true, message: this.$t('5330d77') },
          // { validator: lenIs6 },
        ],
        contacts: [
          { required: true, message: this.$t('97ea28f') },
          // { validator: lenIs6 },
        ],
        telephone: [
          { required: true, message: this.$t('18b2b78') },
          // { validator: lenIs6 },
          { validator: validator.phone_zh },
        ],
        email: [
          { required: true, message: this.$t('133bec8') },
          { validator: validator.email },
        ],
        annex: [
          { required: true, message: this.$t('cddd41b') },
          // { validator: lenIs6 },
        ],
      }
    },
    submitFormText() {
      let result = this.$t('284d1ae')
      if (this.editId >= 0) {
        result = this.$t('d1a4ef7')
      }
      return result
    },
    type() {
      let res = this.isLaw ? 'TRANSLATE' : 'LAW'
      if (!(this.isLaw || this.isTranslate)) {
        res = this.releaseForm.demandType
      }
      return res
    },
    isLaw() {
      const type = this.userInfo.userType
      return type === 'LAW_OFFICE' || type === 'LAWYER'
    },
    isTranslate() {
      const type = this.userInfo.userType
      return type === 'TRANSLATE'
    },
    fieldOptions() {
      const dicts = this.dictionaries
      let dic = this.isLaw
        ? dicts['TRANSLATION_BUSINESS_TYPE']
        : dicts['BUSINESS_TYPE']
      if (!(this.isLaw || this.isTranslate)) {
        dic = this.releaseForm.demandType === 'LAW' ? dicts['BUSINESS_TYPE'] : dicts['TRANSLATION_BUSINESS_TYPE']
      }
      return dic ? dic.list : []
    },
  },
  watch: {
    detailsData(newVal) {
      if (newVal) {
        const provinceId = newVal.provinceId ? newVal.provinceId : ''
        let businessType = newVal.businessTypeId ? newVal.businessTypeId.split(',') : []
        businessType = businessType.map(val => Number(val))
        this.releaseForm = {
          demandType: newVal.type,
          businessType: businessType,
          title: newVal.title,
          content: newVal.content,
          timeLimit: [
            new Date(newVal.startTime).getTime(),
            new Date(newVal.endTime).getTime(),
          ],
          country: Number(newVal.countryId),
          province: Number(provinceId),
          urgentStatus: newVal.urgentStatus,
          contacts: newVal.contactPerson,
          telephone: newVal.contactPhone,
          email: newVal.email,
          annex: newVal.annex,
          fileName: newVal.fileName,
        }
        if (newVal.annex && newVal.fileName) {
          this.fileList = [{
            name: newVal.fileName,
            url: newVal.annex,
          }]
        }
        this.getBusinessTypeList(newVal.type)
      }
    },
    editId(newVal) {
      if (newVal) {
        this.getData()
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions([
      'getDictionaryList',
      'getLawRequirementsDetails',
      'getProvinceList',
      'getDictionary',
    ]),

    onSuccess(response, file, fileList) {
      if (response.code === '000000') {
        this.releaseForm.annex = response.data.url
        this.releaseForm.fileName = response.data.fileName
      }
    },
    handleRemove() {
      this.releaseForm.annex = ''
      this.releaseForm.fileName = ''
      this.fileList = []
    },
    beforeAvatarUpload(file) {
      const arr = this.accept
      const isType = arr.includes(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isType) {
        this.$message.error('可上传文件类型：png、jpg、word、pdf!')
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      const r = isType && isLt10M
      console.log('rr', r)
      return r
    },
    editData() {
      if (this.editId >= 0) {
        this.getLawRequirementsDetails({
          id: this.editId,
          type: 'reset',
        })
      }
    },
    async getData() {
      this.getDictionaryList({
        type: 'COUNTRY',
      })
      this.getProvinceList(0)
      // this.getBusinessTypeList()
      this.editData()
      this.getDictionary({ type: 'BUSINESS_TYPE' })
      this.getDictionary({ type: 'TRANSLATION_BUSINESS_TYPE' })
    },
    changeRadio() {
      this.releaseForm.businessType = ''
      this.getBusinessTypeList()
    },
    getBusinessTypeList(defaultType) {
      const demandType = defaultType || this.releaseForm.demandType
      const obj = {
        LAW: 'BUSINESS_TYPE',
        TRANSLATE: 'TRANSLATION_BUSINESS_TYPE',
        OTHER: 'OTHER_CONSULTING_TYPE',
      }
      const type = obj[demandType]
      API.common
        .getDictionaryList({
          type,
        })
        .then((res) => {
          this.businessTypeList = res.content
        })
    },
    cancelSubmitForm() {
      if (this.editId >= 0) {
        this.$emit('editSuccess')
      } else {
        this.$router.go(-1)
      }
    },
    submitForm() {
      this.$refs.releaseForm.validate((valid) => {
        if (valid) {
          if (this.editId >= 0) {
            this.editLawRequirements()
          } else {
            this.addLawRequirements()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleUpData() {
      const form = this.releaseForm
      let businessType = form.businessType
      businessType = Array.isArray(businessType) ? businessType.join() : ''
      const data = {
        // type: form.demandType,
        type: this.type,
        businessTypeId: businessType,
        title: form.title,
        content: form.content,
        startTime: form.timeLimit[0],
        endTime: form.timeLimit[1],
        country: form.country,
        province: form.province,
        urgentStatus: form.urgentStatus,
        contactPerson: form.contacts,
        contactPhone: form.telephone,
        email: form.email,
        annex: form.annex,
        fileName: form.fileName,
      }
      return data
    },
    addLawRequirements() {
      const data = this.handleUpData()
      API.service.addLawRequirements(data).then(
        (res) => {
          this.handleRes(res)
        },
        (error) => {
          console.log('error', error)
        }
      )
    },
    editLawRequirements() {
      const data = this.handleUpData()
      API.service.editLawRequirements(this.editId, data).then(
        (res) => {
          this.handleEditRes(res)
        },
        (error) => {
          console.log('error', error)
        }
      )
    },
    handleRes(res) {
      if (res.code === 200 || res.code === '000000') {
        this.$message({
          message: '发布成功',
          type: 'success',
        })
        this.$router.push('/service/demand')
      } else {
        this.$message({
          message: res.message,
          type: 'error',
        })
      }
    },
    handleEditRes(res) {
      this.$message({
        message: res.message,
        type: 'success',
      })
      this.$emit('editSuccess')
    },
    autoPrefix(name, key = 'label') {
      let str = this[key][name]
      str = this.$t(str)
      if (key === 'label') {
        str += '：'
      }
      return str
    },
    changePage(page) {
      console.log('page', page)
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.releaseForm {
  width: 600px;
  margin: 0 auto;
  padding: 40px 0;
  & /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  .contentInput /deep/ .el-input__count {
    line-height: initial;
  }
  .uploadannex /deep/ .addButtom {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  & /deep/ .el-cascader,
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .warning /deep/ .el-form-item__content {
    font-size: 12px;
    font-weight: 400;
    color: #f5222d;
    text-align: center;
  }
  .countryContainer {
    display: flex;
    & /deep/ .el-select {
      width: 180px;
    }
  }
  .buttonCntainer {
    width: 100%;
    & /deep/ .el-form-item__content {
      display: flex;
      justify-content: space-between;
    }

    .cancel {
      width: 160px;
    }
    .release {
      width: 160px;
    }
  }
}
</style>
